import React, { useEffect, useState } from "react";
import webContent from "../include/Config";
import axios from "axios";

export const Gallery = () => {
  const [galleryimages, setGalleryImages] = useState([]);
  const galleryImg = async () => {
    axios
      .post(
        webContent.webLink + "/api/gallery.php",
        { SUBMIT: "GALLERY", LIMIT: 3 },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //console.log(res.data);
        setGalleryImages(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };

  useEffect(() => {
    galleryImg();
  }, []);
  return (
    <>
      <section
        class="breadcrumb-main pb-20 pt-14"
        style={{ backgroundImage: "url(images/bg/bg1.jpg)" }}
      >
        <div
          class="section-shape section-shape1 top-inherit bottom-0"
          style={{ backgroundImage: "url(images/shape8.png)" }}
        ></div>
        <div class="breadcrumb-outer">
          <div class="container">
            <div class="breadcrumb-content text-center">
              <h1 class="mb-3">Gallery</h1>
              <nav aria-label="breadcrumb" class="d-block">
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Our Gallery
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div class="dot-overlay"></div>
      </section>
      <div class="gallery pt-6 pb-0">
        <div class="container">
          <div class="section-title mb-6 text-center w-75 mx-auto">
            <h4 class="mb-1 theme1">Our Gallery</h4>
            <h2 class="mb-1">
              Some Beautiful <span class="theme">Snapshoots</span>
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore.
            </p>
          </div>
          <div class="row">
            {galleryimages.length>0&&galleryimages.map((galleryObj,index)=>(
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div class="gallery-item mb-4 rounded overflow-hidden">
                <div class="gallery-image">
                  <img src={webContent.webLink+"/assets/gallery/"+galleryObj.images} />
                </div>
                <div class="gallery-content">
                  {/* <h5 class="white text-center position-absolute bottom-0 pb-4 left-50 mb-0 w-100">
                    Barcelona - Spain
                  </h5> */}
                  <ul>
                    <li>
                      <a
                        href="images/trending/trending1.jpg"
                        data-lightbox="gallery"
                        data-title="Title"
                      >
                        <i class="fa fa-eye"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
