import { React, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, EffectCube, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import { TourWidgets } from "../widgets/TourWidgets";
import { Testimonials } from "../widgets/Testimonials";
import { TopDeals } from "../widgets/TopDeals";
import { TourGuide } from "../widgets/TourGuide";
import { OurPartner } from "../widgets/OurPartner";
import "react-responsive-modal/styles.css";
import { Link } from "react-router-dom";
import { Services } from "./Services";
import { Header } from "../include/Header";
import { HelmetData } from "react-helmet-async";
import { Helmet } from "react-helmet";
import { Modal } from "react-responsive-modal";
import { TravelQuery } from "../popup/TravelQuery";
export const Home = () => {
  const [open, setOpen] = useState(true);
  useEffect(() => {
    setOpen(true);
  }, []);
  const onCloseModal = () => {
    
    setOpen(false)
  };
  return (
    <>
      <Modal open={open} onClose={onCloseModal} center>
        <TravelQuery />
      </Modal>
      <section class="banner overflow-hidden">
        <div class="slider top50">
          <Swiper
            class="swiper-container"
            modules={[Navigation, A11y, EffectCube, Autoplay]}
            loop={false}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            spaceBetween={20}
            slidesPerView={1}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            pagination={{ clickable: true }}
            //scrollbar={{ draggable: false }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            effect={"slide"}
            cubeEffect={{
              shadow: false,
              slideShadows: false,
              shadowOffset: 20,
              shadowScale: 0.94,
            }}
          >
            <SwiperSlide class="swiper-slide">
              <div class="slide-inner">
                <div
                  class="slide-image"
                  style={{ backgroundImage: "url(images/slider/1.jpg)" }}
                ></div>
                <div class="swiper-content">
                  <div class="entry-meta mb-2">
                    <h5 class="entry-category mb-0 white">Amazing Places</h5>
                  </div>
                  <h1 class="mb-2">
                    <a href="tour-single.html" class="white">
                      Make Your Trip Fun & Noted
                    </a>
                  </h1>
                  <p class="white mb-4">
                    Take photos and videos to capture your travel experiences.
                    Create a digital or physical travel journal to document your
                    journey, including the places you've visited
                  </p>
                  <Link to="/tourpackages" class="nir-btn">
                    Discover More
                  </Link>
                </div>
                <div class="dot-overlay"></div>
              </div>
            </SwiperSlide>
            <SwiperSlide class="swiper-slide">
              <div class="slide-inner">
                <div
                  class="slide-image"
                  style={{ backgroundImage: "url(images/slider/2.jpg)" }}
                ></div>
                <div class="swiper-content">
                  <div class="entry-meta mb-2">
                    <h5 class="entry-category mb-0 white">Explore Travel</h5>
                  </div>
                  <h1 class="mb-2">
                    <a href="tour-single.html" class="white">
                      Start Planning Your Dream Trip
                    </a>
                  </h1>
                  <p class="white mb-4">
                    Once you have everything planned, remember to stay flexible
                    and embrace the unexpected. Allow yourself to immerse in the
                    local culture and savor the unique experiences along the way
                  </p>
                  <div class="slider-button d-flex justify-content-center">
                    <Link to="/contactus" class="nir-btn-black">
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div class="dot-overlay"></div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </section>

      <TourWidgets />
      <Services />

      <Testimonials />

      <OurPartner />
    </>
  );
};
