import {React,useState} from "react";

export const PrivacyPolicy = () => {
 
  return (
    <>
      <section
        className="breadcrumb-main pb-20 pt-14"
        style={{ backgroundImage: "url(images/bg/bg1.jpg)" }}
      >
        <div
          className="section-shape section-shape1 top-inherit bottom-0"
          style={{ backgroundImage: "url(images/shape8.png)" }}
        ></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h1 className="mb-3">Privacy Policy</h1>
              <nav aria-label="breadcrumb" className="d-block">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Privacy Policy
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>

      <section
        className="about-us pt-6"
        style={{
          backgroundImage: "url(images/background_pattern.png)",
          backgroundPosition: "bottom right",
        }}
      >
        <div className="container">
          <div className="about-image-box">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-lg-12 ps-4">
                <div className="about-content text-center text-lg-start">
                  <h4 className="theme d-inline-block mb-0">Get To Know Us</h4>
                  <h2 className="border-b mb-2 pb-1">Our Privacy Policy</h2>
                  <p className="border-b mb-2 pb-2">
                    <b>Choice of Vehicle:</b>
                    <br />
                    We provide vehicles as Budget or Standard Family or Luxury
                    Family. We can not guarantee you of a particular vehicle as
                    Wagon R in Budget or Bolero in Standard Family or Innova in
                    Luxury Family, although if you are comfortable or obsessed
                    with any particular vehicle, let us know from the beginning
                    and we will try our best to provide you the same.
                    <br />
                    <br />
                    <b>Vehicle Allotment System:</b>
                    <br />
                    The vehicle used for your entire tour will be strictly on
                    Point to Point Basis. In any circumstances vehicle will not
                    be provided on Disposal Basis. Vehicle will be changed
                    almost everyday. We will SMS you driver and vehicle details
                    two hours prior to your pick up time.
                    <br />
                    <br />
                    <b>AC and It's Uses:</b>
                    <br />
                    In plains such as Siliguri or while vehicle is plying
                    down-hill from any hilly area to Siliguri, AC will be used
                    as per customers instruction, but while going up hill or
                    plying in hilly area AC will strictly be used as per drivers
                    instruction
                    <br />
                    <br />
                    <b>Timing:</b>
                    <br />
                    <b>1.</b> For any sightseeing it may be half day or full day
                    or any excursion to any place, time mentioned in proposal is
                    from vehicle reporting time.
                    <br />
                    <b>2.</b> In Half day/Full day Sightseeing we always mention
                    maximum points can be covered /visited in that period. It is
                    up to you how many points you can cover in that time.
                    <br />
                    <b>3.</b> Tsomgo Lake/ Nathula vehicle reporting time in
                    normal/off season is 7.30 am to 8.30 am & as there is huge
                    rush in peak season, getting a permit for the same may take
                    some extra time so in peak season vehicle reporting time
                    will be 9 am to 10.30 am.
                    <br />
                    <b>4.</b> Tiger Hill vehicle reporting time is 3.30 am to 4
                    am & if anyone starts after 4.30 am we will not be held
                    responsible for missing the sunrise, and also if for any
                    other reason Sunrise is missed, we will not be held
                    responsible.
                    <br />
                    <br />
                    <b>Change of Day:</b>
                    <br />
                    If any Sightseeing places are closed due to any reason or
                    can not visit any sightseeing place for any reason
                    whatsoever on the scheduled day, it cannot be covered next
                    day in any circumstances.
                    <br />
                    <br />
                    <b>Change of Route:</b>
                    <br />
                    In the event of change of route for any reason such as
                    Landslide, Strike, Political problems etc. at the time of
                    transfer from one place to another, guest has to pay for the
                    change of route directly to the driver in cash
                    <br />
                    <br />
                    <b>Alteration of itinerary:</b>
                    <br />
                    If at any point of time, you need any alteration in your
                    itinerary, you are requested to contact your concerned tour
                    manager/ customer care executive for the changes and it's
                    charges if any. You are requested not to make any changes in
                    your itinerary without your concerned tour manager/ customer
                    care executives knowledge.
                    <br />
                    <br />
                    <b>Nathula Pass & it's Permit:</b>
                    <br />
                    Nathula Pass can be visited along with Tsongo Lake and New
                    Baba Mandir Excursion with permit from Tourism and Civil
                    Aviation Department in Gangtok.
                    <br />
                    As per the itinerary (standard package by drivers union) we
                    will take you to New Baba Mandir, if anyone wants to visit
                    Old Baba Mandir situated at Silk Route, has to pay extra to
                    driver directly. Nathula Pass is closed on Monday and
                    Tuesday for Indian Nationals, Foreign Nationals are not
                    allowed on any day. 2 passport photos and photo address ID
                    proof of each member is required for the permit. Permit for
                    Nathula costs Rs.4000/- per vehicle (Type of vehicle or
                    number of Pax does not matter) If you are a group of less
                    than 6 people then in peak season you may get a clubbed
                    Nathula permit but at the same price. Now let me clear what
                    is clubbed Nathula permit in peak season Sikkim tourism
                    department wants to send maximum tourist to Nathula Pass so
                    to ensure that they issue clubbed Nathula permit in this
                    system you can visit Tsomgo Lake & Baba Mandir in your
                    exclusive vehicle but from Nathula gate you have to go with
                    some other passengers some times in your vehicle/any others
                    vehicle.
                    <br />
                    <br />
                    <b>Zero Point:</b>
                    <br />
                    Zero Point can be visited along with Yumthang Valley on
                    paying Rs.3000/- per vehicle (Type of vehicle or number of
                    Pax does not matter)
                    <br />
                    <br />
                    <b>Extra Payable:</b>
                    <br />
                    All entrance fees at various Sightseeing places, Toll,
                    Parking charges at various places has to pay by the guest.
                    <br />
                    <br />
                    <b>Others:</b>
                    <br />
                    All Valuables and personal belongings are the responsibility
                    of the client. In case of theft or lost of clients
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="white-overlay"></div>
      </section>
    </>
  );
};
