import React, { useState } from "react";
import { Seo } from "../Seo";
import { useLocation } from "react-router-dom";
export const Aboutus = () => {
  const location = useLocation();

  useState(()=>{
    window.scrollTo(0, 0)
  })
  return (
    <>
    <Seo
          location={location}
        />
      <section
        className="breadcrumb-main pb-20 pt-14"
        style={{ backgroundImage: "url(images/bg/bg1.jpg)" }}
      >
        <div
          className="section-shape section-shape1 top-inherit bottom-0"
          style={{ backgroundImage: "url(images/shape8.png)" }}
        ></div>
        <div className="breadcrumb-outer">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h1 className="mb-3">About Us</h1>
              <nav aria-label="breadcrumb" className="d-block">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    About Us
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="dot-overlay"></div>
      </section>

      <section
        className="about-us pt-6"
        style={{
          backgroundImage: "url(images/background_pattern.png)",
          backgroundPosition: "bottom right",
        }}
      >
        <div className="container">
          <div className="about-image-box">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-lg-6 ps-4">
                <div className="about-content text-center text-lg-start">
                  <h4 className="theme d-inline-block mb-0">Get To Know Us</h4>
                  <h2 className="border-b mb-2 pb-1">
                    Explore All Tour with us.
                  </h2>
                  <p className="border-b mb-2 pb-2">
                    Welcome to [<b>Siliguri Luxury Cab Service</b>], your
                    trusted partner in creating unforgettable travel
                    experiences!
                    <br />
                    <br />
                    At [<b>Siliguri Luxury Cab Service</b>], we believe that
                    traveling is more than just visiting new destinations; it's
                    about immersing yourself in different cultures, exploring
                    breathtaking landscapes, and creating lifelong memories. We
                    are passionate about crafting tailor-made journeys that
                    cater to your unique preferences, ensuring that every moment
                    of your trip is truly extraordinary.
                    <br />
                    <br />
                    We are proud to collaborate with a vast network of trusted
                    partners, including top-notch hotels, local guides, and
                    transportation providers, to ensure that you receive the
                    highest quality of service during your journey. We handpick
                    accommodations that offer comfort, convenience, and a
                    genuine sense of hospitality, allowing you to relax and
                    rejuvenate after each day of exploration.
                    <b />
                    Embark on a journey of discovery with [
                    <b>Siliguri Luxury Cab Service</b>] and let us be your guide
                    to the India's most captivating destinations. Contact us
                    today and let the adventure begin!
                    <b />
                  </p>
                  <div className="about-listing">
                    <ul className="d-flex justify-content-between">
                      <li>
                        <i className="icon-location-pin theme"></i> Tour Guide
                      </li>
                      <li>
                        <i className="icon-briefcase theme"></i> Friendly Price
                      </li>
                      <li>
                        <i className="icon-folder theme"></i> Reliable Tour
                        Package
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-4 pe-4">
                <div
                  className="about-image"
                  style={{ animation: "none", background: "transparent" }}
                >
                  <img src="images/aboutus.jpg" alt="" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="counter-main w-75 float-start z-index3 position-relative">
                  <div className="counter p-4 pb-0 box-shadow bg-white rounded mt-minus">
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                        <div className="counter-item border-end pe-4">
                          <div className="counter-content">
                            <h2 className="value mb-0 theme">20</h2>
                            <span className="m-0">Years Experiences</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                        <div className="counter-item border-end pe-4">
                          <div className="counter-content">
                            <h2 className="value mb-0 theme">530</h2>
                            <span className="m-0">Tour Packages</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                        <div className="counter-item border-end pe-4">
                          <div className="counter-content">
                            <h2 className="value mb-0 theme">850</h2>
                            <span className="m-0">Happy Customers</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                        <div className="counter-item">
                          <div className="counter-content">
                            <h2 className="value mb-0 theme">320</h2>
                            <span className="m-0">Award Winning</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="white-overlay"></div>
      </section>

      {/* <section
        className="testimonial pt-9"
        style={{ backgroundImage: "url(images/testimonial.png)" }}
      >
        <div className="container">
          <div className="section-title mb-6 text-center w-75 mx-auto">
            <h4 className="mb-1 theme1">Our Testimonails</h4>
            <h2 className="mb-1">
              Good Reviews By <span className="theme">Clients</span>
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore.
            </p>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-5 pe-4">
              <div className="testimonial-image">
                <img src="images/travel2.png" alt="" />
              </div>
            </div>
            <div className="col-lg-7 ps-4">
              <div className="row review-slider">
                <div className="col-sm-12 item">
                  <div className="testimonial-item1 rounded">
                    <div className="author-info mt-2 d-flex align-items-center mb-4">
                      <a href="#">
                        <img src="images/testimonial/img1.jpg" alt="" />
                      </a>
                      <div className="author-title ms-3">
                        <h5 className="m-0 theme">Jared Erondu</h5>
                        <span>Supervisor</span>
                      </div>
                    </div>
                    <div className="details">
                      <p className="m-0">
                        <i className="fa fa-quote-left me-2 fs-1"></i>Lorem
                        Ipsum is simply dummy text of the printing andypesetting
                        industry. Lorem ipsum a simple Lorem Ipsum has been the
                        industry's standard dummy hic et quidem. Dignissimos
                        maxime velit unde inventore quasi vero dolorem.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};
