import { React, useState } from "react";
import { useLocation } from "react-router-dom";
import webContent from "../include/Config";
import { TourDetailsLeft } from "../widgets/TourDetailsLeft";
import { OurPartner } from "../widgets/OurPartner";
import { NearestTourLeft } from "../widgets/NearestTourLeft";
export const TourDetails = () => {
  const { state } = useLocation();
  //const nearestTourPac = state.tourDetailsView;
  const nearestTourInfo = state.tourInfo.tourDetails;
  const tourImages = state.tourInfo.tourImages;
 
  useState(() => {
    window.scrollTo(0, 0)
  },[]);
  //console.log("Nearest:"+nearestTourPac)
  return (
    <>
      <div className="banner trending overflow-hidden">
        <div
          className="section-shape section-shape1 top-inherit bottom-0"
          style={{ backgroundImage: "url(images/shape8.png)" }}
        ></div>
        <div className="banner-slide">
          <div className="row shop-slider">
            <div className="col-lg-4 p-0">
              <div className="trend-item1 box-shadow bg-white text-center">
                <div className="trend-image position-relative">
                  <img
                    src="images/new-deal/deal1.jpg"
                    alt="image"
                    className=""
                  />
                  <div className="overlay"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 p-0">
              <div className="trend-item1 box-shadow bg-white text-center">
                <div className="trend-image position-relative">
                  <img
                    src="images/new-deal/deal2.jpg"
                    alt="image"
                    className=""
                  />
                  <div className="overlay"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 p-0">
              <div className="trend-item1 box-shadow bg-white text-center">
                <div className="trend-image position-relative">
                  <img
                    src="images/new-deal/deal3.jpg"
                    alt="image"
                    className=""
                  />
                  <div className="overlay"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 p-0">
              <div className="trend-item1 box-shadow bg-white text-center">
                <div className="trend-image position-relative">
                  <img
                    src="images/new-deal/deal4.jpg"
                    alt="image"
                    className=""
                  />
                  <div className="overlay"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 p-0">
              <div className="trend-item1 box-shadow bg-white text-center">
                <div className="trend-image position-relative">
                  <img
                    src="images/new-deal/deal5.jpg"
                    alt="image"
                    className=""
                  />
                  <div className="overlay"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-breadcrum position-absolute top-40 mx-auto w-50 start-50 text-center translate-middle">
          <div className="breadcrumb-content text-center">
            <h1 className="mb-0 white">{nearestTourInfo.title}</h1>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {nearestTourInfo.title}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <section className="blog">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-9 mb-4">
              <div className="blog-single">
                <div className="blog-wrapper">
                  <h2 className="lh-sm">{nearestTourInfo.title}</h2>
                  <div className="blog-content first-child-cap">
                    <p className="mb-3">
                      {nearestTourInfo.description.substring(0, 150)}
                      <br />
                      <br />
                      <img
                        style={{ width: "100%" }}
                        src={
                          webContent.webLink +
                          "/assets/tour/" +
                          tourImages[0].images
                        }
                        alt="image"
                        className="mb-3 rounded"
                      />
                      {nearestTourInfo.description.substring(150)}
                    </p>
                  </div>

                  <div className="blog-quote mb-4 rounded">
                    <p className="white">
                      Experienced tour operator, best services at affordable
                      costs. We provide travelers with our great accommodations,
                      tours packages & services.
                    </p>
                    <span className="white">By Susanta Bhowmick</span>
                    <i className="fa fa-quote-left"></i>
                  </div>
                  <div className="blog-imagelist mb-4">
                    <div className="row">
                      {tourImages.length > 0 &&
                        tourImages
                          .filter(
                            (items) => items.images !== tourImages[0].images
                          )
                          .map((imgObj, index) => (
                            <div className="col-md-6 col-sm-6 col-xs-12">
                              <img
                                style={{ height: "100%" }}
                                src={
                                  webContent.webLink +
                                  "/assets/tour/" +
                                  imgObj.images
                                }
                                alt="image"
                                className="rounded"
                              />
                            </div>
                          ))}
                    </div>
                  </div>

                  <div className="blog-share d-flex justify-content-between align-items-center mb-4 bg-lgrey border rounded">
                    <div className="blog-share-tag">
                      <ul className="inline">
                        <li>
                          <strong>Posted In: &nbsp;</strong>
                        </li>
                        <li>
                          <a href="#">Nearest Tour,&nbsp;&nbsp; </a>
                        </li>
                        <li>
                          <a href="#">Tour Package Booking,&nbsp;&nbsp; </a>
                        </li>
                        <li>
                          <a href="#">Car Booking,&nbsp;&nbsp; </a>
                        </li>
                        <li>
                          <a href="#">Travel&nbsp;&nbsp; </a>
                        </li>
                      </ul>
                    </div>
                    <div className="header-social">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-google-plus"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="blog-author mb-4 bg-grey border rounded">
                  <div className="blog-author-item">
                    <div className="row d-flex justify-content-between align-items-center">
                      <div className="col-md-3 col-sm-3 col-xs-12">
                        <div className="blog-thumb text-center position-relative">
                          <img src="images/owner.jpg" alt="" />
                        </div>
                      </div>
                      <div className="col-md-9 col-sm-9 col-xs-12">
                        <h3 className="title pink">
                          <a href="#">
                            Susanta Bhowmick <span>[Owner]</span>
                          </a>
                        </h3>
                        <p className="m-0">
                          We are help you to provide best tour packages, with
                          affordable price, makes your journey joyfull call us@{" "}
                          <b style={{ fontSize: "18px" }}>
                            7602628549 / 7908833511
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bigyapan mb-4">
                  <a href="#">
                    <img
                      src="images/bigyapan1.jpg"
                      alt=""
                      className="rounded"
                    />
                  </a>
                </div>

                <div className="single-comments single-box mb-4">
                  <h4 className="mb-4">Showing 1 verified comments</h4>
                  <div className="comment-box">
                    <div className="comment-image mt-2">
                      <img
                        src="images/owner.jpg"
                        style={{ width: "100px" }}
                        alt="image"
                      />
                    </div>
                    <div className="comment-content rounded">
                      <h4 className="mb-1 Soldman Kell">Admin</h4>
                      <p className="comment-date">May 1, 2023 at 10:46 am</p>
                      <div className="comment-rate">
                        <div className="rating">
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                        </div>
                        <span className="comment-title">
                          "No 1 Service Provider"
                        </span>
                      </div>
                      <p className="comment">
                        Experienced tour operator, best services at affordable
                        costs. We provide travelers with our great
                        accommodations, tours packages & services.
                      </p>
                      <div className="comment-like">
                        <div className="like-title float-left">
                          <a href="#" className="nir-btn mr-2">
                            Reply
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="single-add-review">
                  <h4 className="">Write a Review</h4>
                  <form>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group mb-2">
                          <input type="text" placeholder="Name" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-2">
                          <input type="email" placeholder="Email" />
                        </div>
                      </div>
                      <div className="col-lg-12 mb-1">
                        <div className="form-group">
                          <textarea>Comment</textarea>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-btn">
                          <a href="#" className="nir-btn">
                            Submit Review
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <NearestTourLeft />
          </div>
        </div>
      </section>
      <OurPartner />
    </>
  );
};
