import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Home } from "./component/Home";
import { Header } from "./include/Header";
import { Footer } from "./include/Footer";
import { TourDetails } from "./component/TourDetails";
import { Aboutus } from "./component/Aboutus";
import { TourPackages } from "./component/TourPackages";
import { Vehical } from "./component/Vehical";
import { Contact } from "./component/Contact";
import { Gallery } from "./component/Gallery";
import { Hotels } from "./component/Hotels";
import { HotelList } from "./component/HotelList";
import { TourPackageDetails } from "./component/TourPackageDetails";
import { HotelDetails } from "./component/HotelDetails";
import { PrivacyPolicy } from "./component/PrivacyPolicy";
import { SelfDrive } from "./component/SelfDrive";
import { TourPlaces } from "./component/TourPlaces";
import ScrollToTop from "./widgets/ScrollTop";
import { TourPackageDetailsLink } from "./component/TourPackageDetailsLink";
import { Helmet } from 'react-helmet';
import { TourCity } from "./component/TourCity";
import { TourTypePackages } from "./component/TourTypePackages";
function App() {
  return (
    <>
      <Router>
      
        <Header />
        <Routes>
          <Route excet path="/" element={<Home />} />
          <Route path="/tourdetails" element={<TourDetails />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/ourvehicle" element={<Vehical />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/hotels" element={<Hotels />} />
          <Route path="/hotellist" element={<HotelList />} />
          <Route path="/hoteldetails" element={<HotelDetails />} />
          <Route path="/tourpackages" element={<TourPackages />} />
          <Route path="/tourpacktype" element={<TourTypePackages />} />
          <Route path="/tourplaces" element={<TourPlaces />} />
          <Route path="/tourpackdetails" element={<TourPackageDetails />} />
          <Route path="/citytour" element={<TourCity />} />
          <Route path="/:id" element={<TourPackageDetailsLink />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/selfdrive" element={<SelfDrive />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
