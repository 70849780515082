import { React, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import { TravelQuery } from "../popup/TravelQuery";
export const Header = () => {
  const [mobileTab, setMobileTab] = useState(false);
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);
  const onOpenModal = () => {
    setOpen(true);
    //setMobileTab(false);
  };
  const onCloseModal = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    setOpen(false);
  };

  const mobileMenu = () => {
    //setOpen(true);
    if (mobileTab) {
      setMobileTab(false);
    } else {
      setMobileTab(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    //window.scrollTo(0, 0);
    //localStorage.setItem("openquery", 1);
    //setOpen(true);
  }, [pathname]);

  return (
    <>
      <div className="btnquery" onClick={onOpenModal}>
        <i class="fa fa-question-circle" aria-hidden="true"></i>
      </div>
      <header className="main_header_area">
        <div className="header-content py-1 bg-theme">
          <div className="container d-flex align-items-center justify-content-between">
            <div className="links">
              <ul>
                <li>
                  <a href="#" className="white">
                    <i className="icon-location-pin white"></i> Champasari,
                    Samar Nagar, Siliguri-734008
                  </a>
                </li>
                <li>
                  <a href="#" className="white">
                    <i className="icon-clock white"></i>{" "}
                    <a style={{ color: "#FFF" }} href="tel:+917602628549">
                      +91-7602628549
                    </a>{" "}
                    /
                    <a style={{ color: "#FFF" }} href="tel:+917908833511">
                      {" "}
                      7908833511
                    </a> &nbsp;
/&nbsp;
                    <a style={{ color: "#FFF" }} href="tel:+919883457992">
                      {" "}
                      9883457992
                    </a>
                  </a>
                </li>
              </ul>
            </div>
            <div className="links float-right">
              <Link
                to={"/tourplaces"}
                style={{
                  color: "#FFF",
                  background: "#ffc107",
                  borderRadius: "2px",
                  padding: "5px 5px",
                }}
              >
                NEAREST TOUR PLACES
              </Link>
            </div>
          </div>
        </div>

        <div className="header_menu" id="header_menu">
          <nav className="navbar navbar-default">
            <div className="container">
              <div className="navbar-flex dflex align-items-center justify-content-start w-100 pb-1 pt-1">
                <div className="navbar-header" style={{ float: "left" }}>
                  <a className="navbar-brand" href="index.html">
                    <img
                      src="images/logo.png"
                      alt="image"
                      style={{ width: "200px" }}
                    />
                  </a>
                </div>

                <div
                  className="navbar-collapse1 d-flex align-items-center"
                  id="bs-example-navbar-collapse-1"
                  style={{ marginTop: "20px" }}
                >
                  <ul className="nav navbar-nav" id="responsive-menu">
                    <li className="dropdown submenu">
                      <NavLink to="/">Home</NavLink>
                    </li>
                    <li>
                      <NavLink to="/aboutus">About Us</NavLink>
                    </li>

                    <li className="submenu dropdown">
                      <NavLink to="/citytour">Tour Packages</NavLink>
                      <ul className="dropdown-menu">
                        <li className="submenu dropdown">
                          <Link to={"/tourpacktype"}
                           state={{ tour_type:'North East' }}>North East</Link>
                         
                        </li>
                        <li>
                          <Link
                           to={"/tourpacktype"}
                           state={{ tour_type:'Domestic' }}
                          >Domestic</Link>
                        </li>
                        <li>
                          <Link to={"/tourpacktype"}
                           state={{ tour_type:'International' }}>International</Link>
                        </li>
                      </ul>
                    </li>

                    <li className="submenu dropdown">
                      <NavLink to="/ourvehicle">Our Vehicle</NavLink>
                    </li>
                    <li className="submenu dropdown">
                      <NavLink to="/hotels">Hotels</NavLink>
                    </li>
                    <li className="submenu dropdown">
                      <NavLink to="/contactus">Contact Us</NavLink>
                    </li>

                    <li className="submenu dropdown">
                      <span
                        style={{
                          color: "#029e9d",
                          padding: "10px 10px",
                          fontSize: "18px",
                          textTransform: "uppercase",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                        onClick={onOpenModal}
                      >
                        Quick Query
                      </span>
                    </li>

                    {/* <li className="search-main">
                      <a href="#search1" className="mt_search">
                        <i className="fa fa-search"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>

                <div id="slicknav-mobile" style={{ float: "right" }}>
                  <div class="slicknav_menu">
                    <a
                      href="#"
                      onClick={mobileMenu}
                      aria-haspopup="true"
                      tabindex="0"
                      class="slicknav_btn slicknav_collapsed"
                    >
                      <span class="slicknav_menutxt"></span>
                      <span class="slicknav_icon slicknav_no-text">
                        <span class="slicknav_icon-bar"></span>
                        <span class="slicknav_icon-bar"></span>
                        <span class="slicknav_icon-bar"></span>
                      </span>
                    </a>
                    {mobileTab ? (
                      <ul
                        class="slicknav_nav slicknav_hidden"
                        aria-hidden="true"
                        role="menu"
                      >
                        <li className="dropdown submenu">
                          <NavLink onClick={(e) => setMobileTab(false)} to="/">
                            Home
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/aboutus">About Us</NavLink>
                        </li>

                        <li className="submenu dropdown">
                          <NavLink
                            onClick={(e) => setMobileTab(false)}
                            to="/citytour"
                          >
                            Tour Packages
                          </NavLink>
                        </li>

                        <li className="submenu dropdown">
                          <NavLink
                            onClick={(e) => setMobileTab(false)}
                            to="/ourvehicle"
                          >
                            Our Vehicle
                          </NavLink>
                        </li>
                        <li className="submenu dropdown">
                          <NavLink
                            onClick={(e) => setMobileTab(false)}
                            to="/hotels"
                          >
                            Hotels
                          </NavLink>
                        </li>
                        <li className="submenu dropdown">
                          <NavLink
                            onClick={(e) => setMobileTab(false)}
                            to="/contactus"
                          >
                            Contact Us
                          </NavLink>
                        </li>

                        <li>
                          <div
                            className="register-login"
                            style={{ margin: "10px", display: "block" }}
                          >
                            <Link
                              onClick={onOpenModal}
                              className="nir-btn white"
                            >
                              BOOKING QUERY
                            </Link>
                          </div>
                        </li>
                        <li>&nbsp;</li>
                      </ul>
                    ) : (
                      <ul></ul>
                    )}
                  </div>
                </div>
                <Modal open={open} onClose={onCloseModal} center>
                  <TravelQuery />
                </Modal>
              </div>

              {/* <div style={{margin:"20px 0px"}}>7602628549 / 7908833511


                <div className="register-login" style={{float:"right"}}>
                  
                  <Link onClick={onOpenModal} className="nir-btn white">
                    BOOKING QUERY
                  </Link>
                </div>
              </div> */}
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};
