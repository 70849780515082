import React, { useEffect, useState } from "react";
import webContent from "../include/Config";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { TourDetailsLeft } from "../widgets/TourDetailsLeft";
import { OurPartner } from "../widgets/OurPartner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ClipLoader from "react-spinners/ClipLoader";
import { Seo } from "../Seo";
export const TourPackages = () => {
  const {state} = useLocation();
  const maincity = state.main_city;

  //console.log(maincity)
  const [loader, setLoader] = useState(true);
  const [tour, setTour] = useState([]);
  const tourList = async (mcity) => {

    console.log("MainCity"+mcity);
    axios
      .post(
        webContent.webLink + "/api/tourPackage.php",
        { SUBMIT: "TOURPACKAGE",MCITY:mcity},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //console.log(res.data);
        setTour(res.data);
        setLoader(false);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };

  useEffect(() => {
    tourList(maincity);
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <section
        class="breadcrumb-main pb-20 pt-14"
        style={{ backgroundImage: "url(images/bg/bg1.jpg)" }}
      >
        <div
          class="section-shape section-shape1 top-inherit bottom-0"
          style={{ backgroundImage: "url(images/shape8.png)" }}
        ></div>
        <div class="breadcrumb-outer">
          <div class="container">
            <div class="breadcrumb-content text-center">
              <h1 class="mb-3">LIST OF TOUR PACKAGES</h1>
              <nav aria-label="breadcrumb" class="d-block">
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    TOTAL [{tour.length}] TOUR PACKAGES IN [{maincity}]
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div class="dot-overlay"></div>
      </section>

      <section class="blog trending">
        <div class="container">
          <div class="row flex-row-reverse">
            <div class="col-lg-12 ps-lg-4">
              <div class="listing-inner">
                {loader ? (
                  <div style={{ textAlign: "center", margin: "20px" }}>
                    <ClipLoader
                      color="#029e9d"
                      // loading={loading}
                      // cssOverride={override}
                      size={40}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                ) : (
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="list-results d-flex align-items-center justify-content-between">
                        <div class="list-results-sort">
                          <p class="m-0">Showing [{tour.length}] results</p>
                        </div>
                      </div>
                    </div>

                    {tour.length > 0 &&
                      tour.map((tourObj, index) => (
                        <div class="col-lg-4 mb-4">
                          <div class="trend-item rounded box-shadow bg-white trend-box">
                            <div class="trend-image position-relative">
                              <LazyLoadImage
                                height="250px"
                                effect="blur"
                                src={
                                  webContent.webLink +
                                  "/assets/tourpackage/" +
                                  tourObj?.tourpac?.images
                                } // use normal <img> attributes as props
                                width="100%"
                              />
                            </div>
                            <div class="trend-content p-4 pt-5 position-relative">
                              <div class="trend-meta bg-theme white px-3 py-2 rounded">
                                <div class="entry-author">
                                  <i class="icon-calendar"></i>
                                  <span class="fw-bold">
                                    {" "}
                                    {tourObj.tourpac.duration}
                                  </span>
                                </div>
                              </div>
                              <h5 class="theme mb-1">
                                <i class="flaticon-location-pin"></i> [India]
                              </h5>
                              <h3 class="mb-1">
                                <Link to={"/tourpackdetails"}
                          state={{ tourDetailsView: tourObj }}>
                                  {tourObj.tourpac.title == ''
                            ? "UNTITLE"
                            : tourObj.tourpac.title}
                                </Link>
                              </h3>
                              <div class="rating-main d-flex align-items-center pb-2">
                                <div class="rating">
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                </div>
                              </div>
                              <p class="border-b pb-2 mb-2">
                                {tourObj.tourpac.description.substring(0, 150)}
                              </p>
                              <div class="entry-meta">
                        <div class="entry-author d-flex align-items-center">
                          <p class="mb-0">
                            <span class="theme fw-bold fs-5">
                              <i className="fa fa-inr"></i>{" "}
                              {tourObj.tourpac.tour_price}.00
                            </span>{" "}
                            | Per person
                          </p>
                        </div>
                      </div><br />
                              <Link
                                to={"/tourpackdetails"}
                                state={{ tourDetailsView: tourObj }}
                                class="nir-btn"
                              >
                                View Tour Package
                              </Link>
                              {/* <div class="entry-meta">
                      <div class="entry-author d-flex align-items-center">
                        <p class="mb-0">
                          <span class="theme fw-bold fs-5"> $ {tourObj.tourDetails.tour_price}</span> | Per
                          person
                        </p>
                      </div>
                    </div> */}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}

                {/* <div class="pagination-main text-center">
                  <ul class="pagination">
                    <li>
                      <a href="#">
                        <i
                          class="fa fa-angle-double-left"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    <li class="active">
                      <a href="#">1</a>
                    </li>
                    <li>
                      <a href="#">2</a>
                    </li>
                    <li>
                      <a href="#">3</a>
                    </li>
                    <li>
                      <a href="#">4</a>
                    </li>
                    <li>
                      <a href="#">
                        <i
                          class="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <OurPartner />
    </>
  );
};
