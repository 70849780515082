import React, { useEffect, useState } from "react";
import webContent from "../include/Config";
import axios from "axios";
import { useLocation,Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ClipLoader from "react-spinners/ClipLoader";
import { OurPartner } from "../widgets/OurPartner";
import { Seo } from "../Seo";
export const TourCity = () => {
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [city, setCity] = useState([]);
  const tourDetailsCity = async () => {
    axios
      .post(
        webContent.webLink + "/api/tourCity.php",
        { SUBMIT: "TOURCITY" },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setCity(res.data);
        setLoader(false);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };

  useEffect(() => {
    tourDetailsCity();
    window.scrollTo(0, 0);
  }, []);
  return <>
  
  <Seo location={location} />
      <section
        class="breadcrumb-main pb-20 pt-14"
        style={{ backgroundImage: "url(images/bg/bg1.jpg)" }}
      >
        <div
          class="section-shape section-shape1 top-inherit bottom-0"
          style={{ backgroundImage: "url(images/shape8.png)" }}
        ></div>
        <div class="breadcrumb-outer">
          <div class="container">
            <div class="breadcrumb-content text-center">
              <h1 class="mb-3">LIST OF TOUR PACKAGES</h1>
              <nav aria-label="breadcrumb" class="d-block">
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    WE PROVIDE [{city.length}] CITY'S IN OUR PACKAGES
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div class="dot-overlay"></div>
      </section>

      <section class="blog trending">
        <div class="container">
          <div class="row flex-row-reverse">
            <div class="col-lg-12 ps-lg-4">
              <div class="listing-inner">
                {loader ? (
                  <div style={{ textAlign: "center", margin: "20px" }}>
                    <ClipLoader
                      color="#029e9d"
                      // loading={loading}
                      // cssOverride={override}
                      size={40}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                ) : (
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="list-results d-flex align-items-center justify-content-between">
                        <div class="list-results-sort">
                          <p class="m-0">Showing [{city.length}] results</p>
                        </div>
                      </div>
                    </div>

                    {city.length > 0 &&
                      city.map((cityObj, index) => (
                        <div class="col-lg-4 mb-4">
                          <div class="trend-item rounded box-shadow bg-white trend-box">
                            <div class="trend-image position-relative">
                              <LazyLoadImage
                                height="250px"
                                effect="blur"
                                src={
                                  webContent.webLink +
                                  "/assets/maincity/" +
                                  cityObj?.tourCity?.image
                                } // use normal <img> attributes as props
                                width="100%"
                              />
                              {/* <img
                          src={
                            webContent.webLink +
                            "/assets/tourpackage/" +
                            tourObj?.tourpac?.images
                          }
                          alt="image"
                          class=""
                        /> */}
                              <div class="color-overlay"></div>
                            </div>
                            <div class="trend-content p-4 pt-5 position-relative">
                             
                              
                              <h3 class="mb-1">
                                <a href="tour-grid.html">
                                  {cityObj?.tourCity?.city}{" "}
                                  {/* <span class="ms-2">
                              ({tourObj.tourImages.length})
                            </span> */}
                                </a>
                              </h3>
                              
                            
                              <Link
                                to={"/tourpackages"}
                                state={{ main_city: cityObj?.tourCity?.city }}
                                class="nir-btn"
                              >
                                View Tour Package
                              </Link>
                              {/* <div class="entry-meta">
                      <div class="entry-author d-flex align-items-center">
                        <p class="mb-0">
                          <span class="theme fw-bold fs-5"> $ {tourObj.tourDetails.tour_price}</span> | Per
                          person
                        </p>
                      </div>
                    </div> */}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}

                {/* <div class="pagination-main text-center">
                  <ul class="pagination">
                    <li>
                      <a href="#">
                        <i
                          class="fa fa-angle-double-left"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    <li class="active">
                      <a href="#">1</a>
                    </li>
                    <li>
                      <a href="#">2</a>
                    </li>
                    <li>
                      <a href="#">3</a>
                    </li>
                    <li>
                      <a href="#">4</a>
                    </li>
                    <li>
                      <a href="#">
                        <i
                          class="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <OurPartner />
  </>;
};
