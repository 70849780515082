import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import { TravelQuery } from "../popup/TravelQuery";
export const Services = () => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
    <>
      <section
        class="about-us pb-2 pt-4"
        style={{
          backgroundImage: "url(images/shape4.png)",
          backgroundPosition: "center",
        }}
      >
        <div class="container">
          <div class="section-title mb-6 w-50 mx-auto text-center">
            <h4 class="mb-1 theme1">Our Services</h4>
            <h2 class="mb-1">
              List of <span class="theme">Services We Provide</span>
            </h2>
          </div>

          <div class="why-us">
            <div class="why-us-box">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 mb-4">
                  <div class="why-us-item border rounded bg-white">
                    <div class="why-us-content">
                      <div class="why-us-icon mb-1">
                        <img
                          src="images/services/local-taxi-service.jpg"
                          alt="Travel Support And Assistance"
                        />
                      </div>
                      <div className="p-2">
                        <h4>
                          <Link to="/contactus">Local Taxi Service</Link>
                        </h4>
                        <p class="mb-2">
                          Tour operators provide local taxi services for
                          travelers. We provide local rental, airport drop,
                          one-way, outstation etc.
                          <br />
                          <br />
                          <Link
                            target="_blank"
                            to={"/siliguri_taxi_driver.pdf"}
                            style={{
                              color: "#FFF",
                              background: "#ffc107",
                              borderRadius: "2px",
                              padding: "5px 5px",
                            }}
                          >
                            SILIGURI LOCAL TAXI SERVICE
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-4">
                  <div class="why-us-item border rounded bg-white">
                    <div class="why-us-content">
                      <div class="why-us-icon mb-1">
                        <Link to="/selfdrive">
                          <img
                            src="images/services/self-drive.jpg"
                            alt="Self Drive"
                          />
                        </Link>
                      </div>
                      <div className="p-2">
                        <h4>
                          <Link to="/selfdrive">Self Drive</Link>
                        </h4>
                        <p class="mb-2">
                          Are you Looking for a quality Long Drive Cars for Self
                          Driving? Whether you're looking for a weekend getaway
                          our team can help you find the perfect car for your
                          needs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-4">
                  <div class="why-us-item border rounded bg-white">
                    <div class="why-us-content">
                      <div class="why-us-icon mb-1">
                        <img
                          src="images/services/itinerary-planning.jpg"
                          alt="Itinerary planning"
                        />
                      </div>
                      <div className="p-2">
                        <h4>
                          <Link onClick={onOpenModal}>Itinerary planning</Link>
                        </h4>
                        <p class="mb-2">
                          Tour operators create well-designed itineraries that
                          cater to different interests and preferences. They
                          carefully select destinations, attractions,
                          activities, and accommodations to provide a
                          comprehensive and enjoyable travel experience
                        </p>
                      </div>
                      <Modal open={open} onClose={onCloseModal} center>
                        <TravelQuery />
                      </Modal>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-">
                  <div class="why-us-item border rounded bg-white">
                    <div class="why-us-content">
                      <div class="why-us-icon mb-1">
                        <Link to="/ourvehicle">
                          <img
                            src="images/services/transportation-and-logistics.jpg"
                            alt="Transportation and Logistics"
                          />
                        </Link>
                      </div>
                      <div className="p-2">
                        <h4>
                          <Link to="/ourvehicle">VEHICLE SUPPORT</Link>
                        </h4>
                        <p class="mb-2">
                          Tour operators handle transportation arrangements,
                          including flights, transfers, and ground
                          transportation. They coordinate the logistics of
                          getting travelers from one location to another,
                          ensuring seamless transitions throughout the trip.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-4">
                  <div class="why-us-item border rounded bg-white">
                    <div class="why-us-content">
                      <div class="why-us-icon mb-1">
                        <Link to="/hotels">
                          <img
                            src="images/services/accommodation-arrangements.jpg"
                            alt="Accommodation Arrangements"
                          />
                        </Link>
                      </div>
                      <div className="p-2">
                        <h4>
                          <Link to="/hotels">Accommodation Arrangements</Link>
                        </h4>
                        <p class="mb-2">
                          Tour operators work with various accommodation
                          providers to secure suitable lodging options for their
                          clients. They consider factors such as location,
                          quality, and budget to offer a range of choices that
                          align with travelers' preferences.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-4">
                  <div class="why-us-item border rounded bg-white">
                    <div class="why-us-content">
                      <div class="why-us-icon mb-1">
                        <Link to="/contactus">
                          {" "}
                          <img
                            src="images/services/travel-support-and-assistance.jpg"
                            alt="Travel Support And Assistance"
                          />
                        </Link>
                      </div>
                      <div className="p-2">
                        <h4>
                          <Link to="/contactus">
                            Travel support and assistance
                          </Link>
                        </h4>
                        <p class="mb-2">
                          Tour operators provide assistance and support to
                          travelers before, during, and after the trip. This can
                          include answering inquiries, offering recommendations,
                          and providing emergency contact information.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="white-overlay"></div>
      </section>
    </>
  );
};
