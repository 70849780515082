import React, { useEffect, useState } from "react";
import webContent from "../include/Config";
import { Link } from "react-router-dom";
import axios from "axios";
export const TourDetailsLeft = (props) => {
  const [nearestT, setNearestT] = useState([]);
  const tourPackid = props.tourPackid;
  console.log("TOUR ID:::" + tourPackid);
  //const { state } = useLocation();
  //const nearestTourPac = props.nearestTourPac;
  //console.log("Nearest Tour::::" + nearestTourPac);
  //const [tour, setTour] = useState([]);

  const [nearesttour, setNearestTour] = useState([]);
  const nearesttourList = async (tourid) => {
    axios
      .post(
        webContent.webLink + "/api/nearestTourPlaces.php",
        { SUBMIT: "NERESTPLACES", TOURID: tourid, LIMIT: 10 },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //console.log(res.data);
        setNearestTour(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };
  useEffect(() => {
    nearesttourList(tourPackid);
  });
  return (
    <>
      <div className="col-lg-4 mb-4">
        <div className="sidebar-sticky">
          <div className="detail-sidebar popular-post sidebar-item mb-4">
            {/* <div className="mag-image mb-2 position-relative">
              <img
                src="images/destination/destination10.jpg"
                alt="Image"
                className="rounded"
              />
              <div className="video-button text-center position-absolute top-50 start-0 end-0 z-index1">
                <div className="call-button text-center">
                  <button
                    type="button"
                    className="play-btn js-video-button"
                    data-video-id="152879427"
                    data-channel="vimeo"
                  >
                    <i className="fa fa-play bg-blue"></i>
                  </button>
                </div>
                <div className="video-figure"></div>
              </div>
            </div> */}
            {/* {nearestTourPac.length > 0 &&
              nearestTourPac.map((nearestTourObj, index) => (
                <div className="detail-sidebar-item border-b pb-2 mb-2">
                  <h5>
                    <Link
                      to={"/tourdetails"}
                      state={{ tourDetailsView: nearestTourObj }}
                    >
                      {nearestTourObj.title}
                    </Link>
                  
                    
                    <br />
                    <Link
                      to={"/tourdetails"}
                      state={{ tourDetailsView: nearestTourObj }}
                      style={{ color: "rgb(115, 113, 113)" }}
                    >
                      {nearestTourObj.description.substring(0, 50)}
                    </Link>
                  </h5>
                </div>
              ))}  */}
          </div>
        </div>
        <div className="popular-post sidebar-item mb-4">
          <h3 className="">NEAREST TOUR PLACES</h3>
          <div className="sidebar-tabs">
            <div className="post-tabs">
              <div className="tab-content" id="postsTabContent1">
                <div
                  className="tab-pane fade active show"
                  id="popular"
                  role="tabpanel"
                >
                  {nearesttour.length > 0 &&
                    nearesttour.map((nearestTourObj, index) => (
                      <article className="post mb-3 border-b pb-3">
                        <div className="s-content align-items-center justify-space-between">
                          <div className="sidebar-image w-100 me-3">
                            <Link
                              to={"/tourdetails"}
                              state={{ tourInfo: nearestTourObj }}
                            >
                              <img
                                src={
                                  webContent.webLink +
                                  "/assets/tour/" +
                                  nearestTourObj?.tourImages[0]?.images
                                }
                                alt={nearestTourObj?.tourImages[0]?.id}
                              />
                            </Link>
                          </div>
                          <div className="content-list w-100">
                            <h5 className="mb-1">
                              <Link
                                to={"/tourdetails"}
                                state={{ tourInfo: nearestTourObj }}
                              >
                                {nearestTourObj?.tourDetails?.title.substring(
                                  0,
                                  50
                                )}
                              </Link>
                            </h5>
                            <div className="date">
                              {nearestTourObj?.tourDetails?.description.substring(
                                0,
                                50
                              )}
                            </div>
                          </div>
                        </div>
                      </article>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
