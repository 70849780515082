import React, { useEffect, useState } from "react";
import webContent from "../include/Config";
import axios from "axios";
import { Modal } from "react-responsive-modal";
import { Link } from "react-router-dom";
import { CarQueryWidgets } from "../popup/CarQueryWidgets";
import { TourDetailsLeft } from "../widgets/TourDetailsLeft";
import { OurPartner } from "../widgets/OurPartner";
import { useLocation } from "react-router-dom";
import { Seo } from "../Seo";
export const Vehical = () => {
  const location = useLocation();
  const [vec, setVec] = useState([]);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [vecDetails, setVacDetails] = useState([]);
  const vecList = async () => {
    axios
      .post(
        webContent.webLink + "/api/vehical.php",
        { SUBMIT: "VEHICAL", ID: "", LIMIT: 100 },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //console.log(res.data);
        setVec(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };

  useEffect(() => {
    vecList();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Seo location={location} />
      <section
        class="breadcrumb-main pb-20 pt-14"
        style={{ backgroundImage: "url(images/bg/bg1.jpg)" }}
      >
        <div
          class="section-shape section-shape1 top-inherit bottom-0"
          style={{ backgroundImage: "url(images/shape8.png)" }}
        ></div>
        <div class="breadcrumb-outer">
          <div class="container">
            <div class="breadcrumb-content text-center">
              <h1 class="mb-3">OUR VEHICAL</h1>
              <nav aria-label="breadcrumb" class="d-block">
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Our Vehical
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div class="dot-overlay"></div>
      </section>

      <section class="blog trending">
        <div class="container">
          <div class="row flex-row-reverse">
            <div class="col-lg-12 ps-lg-4">
              <div class="listing-inner">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="list-results d-flex align-items-center justify-content-between">
                      <div class="list-results-sort">
                        <p class="m-0">Showing {vec.length} results</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>
                            <b>VEHICLE DETAILS</b>
                          </th>
                          <th>
                            <b>VEHICLE IMAGES</b>
                          </th>
                          <th>
                            <b>BOOK NOW</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {vec.length > 0 &&
                          vec.map((tourVecObj, index) => (
                            <tr>
                              <td>
                                <h3 class="mb-1">
                                  <a href="tour-grid.html">
                                    {tourVecObj.vecDetails.v_name} |{" "}
                                    {tourVecObj.vecDetails.v_type} |{" "}
                                    {tourVecObj.vecDetails.v_seat + "S"}
                                  </a>
                                </h3>
                                <div class="rating-main d-flex align-items-center pb-2">
                                  <div class="rating">
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <img
                                  style={{ height: "150px" }}
                                  src={
                                    webContent.webLink +
                                    "/assets/vehicle/" +
                                    tourVecObj?.vecImages[0]?.images
                                  }
                                  alt="image"
                                  className="rounded"
                                />
                              </td>
                              <td>
                                <Link
                                  onClick={() => {
                                    onOpenModal(true);
                                    setVacDetails(tourVecObj);
                                  }}
                                  class="nir-btn"
                                >
                                  BOOK NOW
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <Modal open={open} onClose={onCloseModal} center>
                    <CarQueryWidgets state={{ vehicalDetails: vecDetails }} />
                  </Modal>
                </div>
                {/* <div class="pagination-main text-center">
                  <ul class="pagination">
                    <li>
                      <a href="#">
                        <i
                          class="fa fa-angle-double-left"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    <li class="active">
                      <a href="#">1</a>
                    </li>
                    <li>
                      <a href="#">2</a>
                    </li>
                    <li>
                      <a href="#">3</a>
                    </li>
                    <li>
                      <a href="#">4</a>
                    </li>
                    <li>
                      <a href="#">
                        <i
                          class="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
