import React from "react";

export const OurPartner = () => {
  return (
    <>
      <section
        class="about-us pb-2 pt-4"
        style={{
          backgroundImage: "url(images/shape4.png)",
          backgroundPosition: "center",
        }}
      >
        <div class="container">
          <div class="section-title mb-6 w-75 mx-auto text-center">
            <h4 class="mb-1 theme1">Our Partners</h4>
            <h2 class="mb-1">
              Our Awesome <span class="theme">partners</span>
            </h2>
            <p>
              Our partner helps you to provide best car services with affordable price
            </p>
          </div>

          <div class="why-us">
            <div class="why-us-box">
              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 mb-4">
                  <div class="why-us-item border rounded bg-white">
                    <div class="why-us-content">
                      <div class="why-us-icon mb-1">
                        <img
                          src="images/siliguri-luxury-cab-service.jpg"
                          alt="Itinerary planning"
                        />
                      </div>
                      <div className="p-2">
                        <h4>
                          <a href="#">SILIGUIR LAXURY CAB SERVICE</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mb-">
                  <div class="why-us-item border rounded bg-white">
                    <div class="why-us-content">
                      <div class="why-us-icon mb-1">
                        <img
                          src="images/darjeeling-luxury-cab-service.jpg"
                          alt="Transportation and Logistics"
                        />
                      </div>
                      <div className="p-2">
                        <h4>
                          <a href="#">DARJEELING LAXURY CAB SERVICE</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mb-">
                  <div class="why-us-item border rounded bg-white">
                    <div class="why-us-content">
                      <div class="why-us-icon mb-1">
                        <img
                          src="images/gangtok-luxury-cab-service.jpg"
                          alt="Transportation and Logistics"
                        />
                      </div>
                      <div className="p-2">
                        <h4>
                          <a href="#">GANGTOK LAXURY CAB SERVICE</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="white-overlay"></div>
      </section>
    </>
  );
};
