import React, { useEffect, useRef, useState } from "react";
import { TourDetailsLeft } from "../widgets/TourDetailsLeft";
import { Link, useLocation } from "react-router-dom";
import webContent from "../include/Config";
import { OurPartner } from "../widgets/OurPartner";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
export const TourPackageDetails = () => {
  const carouselRef = useRef(null);
  const { state } = useLocation();
  const [loading, setloading] = useState(true);
  const tourPacDetails = state.tourDetailsView.tourpac;
  const tourpacDays = state.tourDetailsView.tourpacdays;
  const tourhotel = state.tourDetailsView.tourhotel;
  const tourvehicle = state.tourDetailsView.tourvehicle;
  //console.log("Nearest Tour:" + nearestPacTour.length);

  const renderArrowPrev = (onClickHandler, hasPrev, label) =>
    hasPrev && (
      <div
        onClick={onClickHandler}
        title={label}
        className=" carousel-arrow carousel-prev"
      >
        Left
        {/* <img src={leftArrow} alt="" /> */}
      </div>
    );

  const renderArrowNext = (onClickHandler, hasNext, label) =>
    hasNext && (
      <div
        onClick={onClickHandler}
        title={label}
        className=" carousel-arrow carousel-next"
      >
        Next
        {/* <img src={rightArrow} alt="" /> */}
      </div>
    );
  useEffect(() => {
    setloading(false);
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="banner trending overflow-hidden">
        <div
          className="section-shape section-shape1 top-inherit bottom-0"
          style={{ backgroundImage: "url(images/shape8.png)" }}
        ></div>
        <div className="banner-slide">
          <div className="row shop-slider">
            <div className="col-lg-4 p-0">
              <div className="trend-item1 box-shadow bg-white text-center">
                <div className="trend-image position-relative">
                  <img
                    src="images/new-deal/deal1.jpg"
                    alt="image"
                    className=""
                  />
                  <div className="overlay"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 p-0">
              <div className="trend-item1 box-shadow bg-white text-center">
                <div className="trend-image position-relative">
                  <img
                    src="images/new-deal/deal2.jpg"
                    alt="image"
                    className=""
                  />
                  <div className="overlay"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 p-0">
              <div className="trend-item1 box-shadow bg-white text-center">
                <div className="trend-image position-relative">
                  <img
                    src="images/new-deal/deal3.jpg"
                    alt="image"
                    className=""
                  />
                  <div className="overlay"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 p-0">
              <div className="trend-item1 box-shadow bg-white text-center">
                <div className="trend-image position-relative">
                  <img
                    src="images/new-deal/deal4.jpg"
                    alt="image"
                    className=""
                  />
                  <div className="overlay"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 p-0">
              <div className="trend-item1 box-shadow bg-white text-center">
                <div className="trend-image position-relative">
                  <img
                    src="images/new-deal/deal5.jpg"
                    alt="image"
                    className=""
                  />
                  <div className="overlay"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-breadcrum position-absolute top-50 mx-auto w-50 start-50 text-center translate-middle">
          <div className="breadcrumb-content text-center">
            <h1 className="mb-0 white">{tourPacDetails.title}</h1>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {tourPacDetails.duration}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <section className="blog">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-7 mb-4">
              <div className="blog-single">
                <div className="blog-wrapper">
                  <h2 className="lh-sm">
                    {tourPacDetails.title} | {tourPacDetails.duration}
                  </h2>
                  <div className="blog-content first-child-cap">
                    <p className="mb-3">
                      {tourPacDetails.description.substring(0, 150)}
                      <br />
                      <br />
                      {/* <img
                        style={{ width: "100%" }}
                        src={
                          webContent.webLink +
                          "/assets/tourpackage/" +
                          tourPacDetails?.images
                        }
                        alt="image"
                        className="mb-3 rounded"
                      /> */}
                      {tourPacDetails.description.substring(150)}
                    </p>
                  </div>

                  {/* <div className="blog-imagelist mb-4">
                    <div className="row">
                      {tourImages.length > 0 &&
                        tourImages
                          .filter(
                            (items) => items.images !== tourImages[0].images
                          )
                          .map((imgObj, index) => (
                            <div className="col-md-6 col-sm-6 col-xs-12">
                              <img
                                style={{ height: "100%" }}
                                src={
                                  webContent.webLink +
                                  "/assets/tour/" +
                                  imgObj.images
                                }
                                alt="image"
                                className="rounded"
                              />
                            </div>
                          ))}
                    </div>
                  </div> */}
                  <div className="blog-quote mb-4 rounded">
                    <p className="white">
                      Experienced tour operator, best services at affordable
                      costs. We provide travelers with our great accommodations,
                      tours packages & services.
                    </p>
                    <span className="white">By Susanta Bhowmick</span>
                    <i className="fa fa-quote-left"></i>
                  </div>

                  <div className="blog-share d-flex justify-content-between align-items-center mb-4 bg-lgrey border rounded">
                    <div className="blog-share-tag">
                      <ul className="inline">
                        <li>
                          <strong>Posted In: &nbsp;</strong>
                        </li>
                        <li>
                          <a href="#">Nearest Tour,&nbsp;&nbsp; </a>
                        </li>
                        <li>
                          <a href="#">Tour Package Booking,&nbsp;&nbsp; </a>
                        </li>
                        <li>
                          <a href="#">Car Booking,&nbsp;&nbsp; </a>
                        </li>
                        <li>
                          <a href="#">Travel&nbsp;&nbsp; </a>
                        </li>
                      </ul>
                    </div>
                    <div className="header-social">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-google-plus"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    id="iternary"
                    class="accrodion-grp faq-accrodion mb-4"
                    data-grp-name="faq-accrodion"
                  >
                    {tourpacDays.length > 0 &&
                      tourpacDays.map((tourDaysObj, index) => (
                        <div class="accrodion active">
                          <div class="accrodion-title rounded">
                            <h5 class="mb-0">
                              <span>{tourDaysObj.tourdays}</span> -{" "}
                              {tourDaysObj.tourdesc}
                            </h5>
                            <br />
                            {tourDaysObj.tourimg != null ? (
                              <img
                                style={{
                                  borderRadius: "10px"
                                 
                                }}
                                src={
                                  webContent.webLink +
                                  "/assets/tourpackage/" +
                                  tourDaysObj.tourimg
                                }
                              />
                            ) : (
                              <span></span>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <h3 className="lh-sm">
                  PROVIDED HOTELS FOR{" "}
                  <span style={{ color: "green" }}>
                    [{tourPacDetails.title}]
                  </span>{" "}
                  PACKAGE
                </h3>
                <div className="blog-imagelist mb-4">
                  <div className="row">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          {/* <th>HOTEL LOCATION</th> */}
                          <th>HOTEL NAME</th>
                          <th>HOTEL LINK</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tourhotel.length > 0 &&
                          tourhotel.map((imgObj, index) => (
                            <tr>
                              {/* <td>{imgObj.hotelDetails?.name}</td> */}
                              <td
                                style={{ fontSize: "25px", color: "#029e9d" }}
                              >
                                {imgObj?.hotelDetails?.hotelname?.toUpperCase()}<br />
                                <img
                                style={{
                                  borderRadius: "10px", width:"200px"
                                 
                                }}
                                src={
                                  webContent.webLink +
                                  "/assets/hotel/" +
                                  imgObj?.hotelDetails?.hotel_img
                                }
                              />
                              </td>
                              <td>
                                <Link
                                  target="_blank"
                                  to={imgObj.hotelDetails?.hotel_link}
                                >
                                  VIEW HOTEL
                                </Link>
                              </td>
                            </tr>
                            // <div
                            //   style={{
                            //     position: "relative",
                            //     marginRight: "10px",
                            //   }}
                            // >
                            //   <img
                            //     style={{ height: "100%" }}
                            //     src={
                            //       webContent.webLink +
                            //       "/assets/hotel/" +
                            //       imgObj.hotelImages
                            //     }
                            //     alt="image"
                            //     className="rounded"
                            //   />
                            //   <div className="tourhotelimg">
                            //     <Link className="nir-btn" target="_blank" to={imgObj.hotelDetails?.hotel_link}>
                            //       VIEW HOTEL
                            //       {/* {imgObj.hotelDetails?.hotel_link} */}
                            //     </Link>
                            //   </div>
                            // </div>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <h3 className="lh-sm">
                  PROVIDED VEHICLE FOR{" "}
                  <span style={{ color: "green" }}>
                    [{tourPacDetails.title}]
                  </span>{" "}
                  PACKAGE
                </h3>

                <div className="blog-imagelist mb-4">
                  <div className="row">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>
                            <b>VEHICLE DETAILS</b>
                          </th>
                          <th>
                            <b>VEHICLE DETAILS</b>
                          </th>
                          <th>
                            <b>TOUR PRICE</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tourvehicle.length > 0 &&
                          tourvehicle.map((vecObj, index) => (
                            <tr>
                              <td>
                                {vecObj.vehicleDetails?.v_name} | Person:{" "}
                                {vecObj.vehicleDetails?.v_seat}
                              </td>
                              <td>
                                <img
                                  style={{ height: "150px" }}
                                  src={
                                    webContent.webLink +
                                    "/assets/vehicle/" +
                                    vecObj.vehicleImages
                                  }
                                  alt="image"
                                  className="rounded"
                                />
                              </td>
                              <td>
                                <span
                                  style={{ color: "red", fontSize: "20px" }}
                                >
                                  <i class="fa fa-rupee"></i>{" "}
                                  {vecObj.vehiclePrice}/-
                                </span>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <div className="blog-author mb-4 bg-grey border rounded">
                  <div className="blog-author-item">
                    <div className="row d-flex justify-content-between align-items-center">
                      <div className="col-md-3 col-sm-3 col-xs-12">
                        <div className="blog-thumb text-center position-relative">
                          <img src="images/owner.jpg" alt="" />
                        </div>
                      </div>
                      <div className="col-md-9 col-sm-9 col-xs-12">
                        <h3 className="title pink">
                          <a href="#">
                            Susanta Bhowmick <span>[Owner]</span>
                          </a>
                        </h3>
                        <p className="m-0">
                          We are help you to provide best tour packages, with
                          affordable price, makes your journey joyfull call us@{" "}
                          <b style={{ fontSize: "18px" }}>
                            7602628549 / 7908833511
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="bigyapan mb-4">
                  <a href="#">
                    <img
                      src="images/bigyapan1.jpg"
                      alt=""
                      className="rounded"
                    />
                  </a>
                </div> */}

                <div className="single-comments single-box mb-4">
                  <h4 className="mb-4">Showing 1 verified comments</h4>
                  <div className="comment-box">
                    <div className="comment-image mt-2">
                      <img
                        src="images/user.png"
                        style={{ width: "100px" }}
                        alt="image"
                      />
                    </div>
                    <div className="comment-content rounded">
                      <h4 className="mb-1 Soldman Kell">Partha Acharyya</h4>
                      <p className="comment-date">May 1, 2023 at 10:46 am</p>
                      <div className="comment-rate">
                        <div className="rating">
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                        </div>
                        <span className="comment-title">
                          "No 1 Service Provider"
                        </span>
                      </div>
                      <p className="comment">
                        Polite accommodating driver. Excellent driving skills
                        .Along with us always on time
                      </p>
                      <div className="comment-like">
                        <div className="like-title float-left">
                          <Link to={"/"} className="nir-btn mr-2">
                            Reply
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="single-add-review">
                  <h4 className="">Write a Review</h4>
                  <form>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group mb-2">
                          <input type="text" placeholder="Name" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-2">
                          <input type="email" placeholder="Email" />
                        </div>
                      </div>
                      <div className="col-lg-12 mb-1">
                        <div className="form-group">
                          <textarea>Comment</textarea>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-btn">
                          <a href="#" className="nir-btn">
                            Submit Review
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <TourDetailsLeft tourPackid={tourPacDetails.id} />
          </div>
        </div>
      </section>
      <OurPartner />
    </>
  );
};
