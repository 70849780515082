import React, { useState, useEffect } from "react";
import webContent from "../include/Config";
import axios from "axios";
import { Link } from "react-router-dom";
export const OthersHotels = () => {
  const [hotel, setHotels] = useState([]);
  const othersHotel = async () => {
    axios
      .post(
        webContent.webLink + "/api/hotels.php",
        { SUBMIT: "HOTELS", LIMIT: 10 },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //console.log(res.data);
        setHotels(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };

  useEffect(() => {
    othersHotel();
  }, []);
  return (
    <>
      <div className="col-lg-3 mb-4">
        <div className="popular-post sidebar-item mb-4">
          <h3 className="">NEAREST TOUR PLACES</h3>
          <div className="sidebar-tabs">
            <div className="post-tabs">
              <div className="tab-content" id="postsTabContent1">
                <div
                  className="tab-pane fade active show"
                  id="popular"
                  role="tabpanel"
                >
                  {hotel.length > 0 &&
                    hotel.map((hotelObj, index) => (
                      <article className="post mb-3 border-b pb-3">
                        <div className="s-content align-items-center justify-space-between">
                          <div className="sidebar-image w-100 me-3">
                            <Link
                              to={"/hoteldetails"}
                              state={{
                                hotelfulldetails:
                                  hotelObj.allHotels[0],
                              }}
                            >
                              <img
                                src={
                                  webContent.webLink +
                                  "/assets/hotel/" +
                                  hotelObj.allHotels[0].hotelImages[0].images
                                }
                                alt={
                                  hotelObj.allHotels[0].hotelDetails.hotelname
                                }
                              />
                            </Link>
                          </div>
                          <div className="content-list w-100">
                            <h5 className="mb-1">
                              <Link
                                to={"/tourdetails"}
                                state={{ tourInfo: hotelObj }}
                              >
                                {hotelObj.allHotels[0].hotelDetails.hotelname}
                              </Link>
                            </h5>
                            <div className="date">
                              {hotelObj.allHotels[0].hotelDetails.hoteldesc.substring(
                                0,
                                50
                              )}
                            </div>
                          </div>
                        </div>
                      </article>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
