import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, EffectCube, Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import webContent from "../include/Config";
import axios from "axios";
import swal from "sweetalert";
import ClipLoader from "react-spinners/ClipLoader";
import { useParams } from "react-router-dom";
export const CarQueryWidgets = (props) => {
  const vehicalDetails = props.state.vehicalDetails.vecDetails;
  const vehicalImages = props.state.vehicalDetails.vecImages;
  //console.log(vehicalDetails.id);

  const [loader,setLoading] = useState(false);
  const [pickup, setPickup] = useState();
  const [drop, setDrop] = useState();
  const [fullname, setFullname] = useState();
  const [contact, setContact] = useState();
  const [traveldate, setTravelDate] = useState();
  const [nodays, setDays] = useState();
  const [person, setPerson] = useState();
  const handlecarQuery = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        webContent.webLink + "/api/mail.php",
        {
          SUBMIT: "VEHICALQUERY",
          vehicalname: vehicalDetails?.v_name,
          pickup: pickup,
          drop: drop,
          fullname: fullname,
          contact: contact,
          traveldate: traveldate,
          nodays: nodays,
          person: person,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status == "success") {
          swal({
            title: "Fullname",
            text: "VEHICAL QUERY SEND SUCCESSFULLY",
            icon: "success",
          }).then((value) => {
            window.location.reload(false);
          });
        }
        //setGalleryImages(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };

  return (
    <>
      <div>
        <section
          className="breadcrumb-main"
          style={{ backgroundImage: "url(images/bg/bg1.jpg)" }}
        >
          <div
            className="section-shape section-shape1 top-inherit bottom-0"
            style={{ backgroundImage: "url(images/shape8.png)" }}
          ></div>
          <div className="breadcrumb-outer">
            <div className="container">
              <div className="breadcrumb-content text-center">
                <h1 className="mb-3">Car Enquery</h1>
                <nav aria-label="breadcrumb" className="d-block">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Car Enquery
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="dot-overlay"></div>
        </section>
        <div style={{ textAlign: "center" }}>
          <h2>Travel Enquery Form {vehicalDetails?.v_name}</h2>
          <p className="mb-2" style={{ padding: "0px 30px" }}>
            Thanks for choosing us to plan your next vacation. Please complete
            this form so we can tailor the perfect trip.{" "}
          </p>
        </div>
        <div className="post-tabs">
          <hr className="log-reg-hr position-relative my-1 mb-2 overflow-visible" />
          <div className="tab-content blog-full" id="postsTabContent">
            <div
              aria-labelledby="login-tab"
              className="tab-pane fade active show"
              id="login"
              role="tabpanel"
            >
              <div className="row">
                <div className="col-lg-6 vehicalcar">
                  <Swiper
                    breakpoints={{
                      576: {
                        width: 576,
                        slidesPerView: 1,
                      },
                      768: {
                        width: 768,
                        slidesPerView: 2,
                      },
                    }}
                    modules={[
                      Navigation,
                      A11y,
                      Pagination,
                      EffectCube,
                      Autoplay,
                    ]}
                    loop={false}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    spaceBetween={20}
                    //slidesPerView={3}
                    navigation={{
                      nextEl: ".slick-prev",
                      prevEl: ".clients-prev",
                    }}
                    pagination={{ clickable: true }}
                    //scrollbar={{ draggable: false }}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    effect={"slide"}
                    cubeEffect={{
                      shadow: false,
                      slideShadows: false,
                      shadowOffset: 20,
                      shadowScale: 0.94,
                    }}
                    style={{ borderRadius: "10px", width: "100%" }}
                  >
                    {vehicalImages.length > 0 &&
                      vehicalImages.map((vehicalImgObj, index) => (
                        <SwiperSlide
                          className="blog-image rounded"
                          style={{ height: "700px" }}
                        >
                          <a
                            href="#"
                            style={{
                              backgroundImage:
                                "url(" +
                                webContent.webLink +
                                "/assets/vehicle/" +
                                vehicalImgObj.images +
                                ")",
                            }}
                          ></a>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
                <div className="col-lg-6">
                  <h4 className="text-center border-b">Vehicle Query</h4>
                 
                  <hr className="log-reg-hr position-relative my-2 overflow-visible" />
                  <form
                    method="post"
                    name="contactform"
                    id="contactform"
                    onSubmit={handlecarQuery}
                  >
                    <div className="form-group mb-2">
                      <label style={{ color: "#222" }}>
                        <b>WHERE YOU WANT TO GO ?</b>
                      </label>
                      <br />
                      <label style={{ color: "#222" }}>PICKUP</label>
                      <input
                        onChange={(e) => setPickup(e.target.value)}
                        type="text"
                        name="pickup"
                        className="form-control"
                        id="fname"
                        placeholder="Your arrival place name ?"
                        required
                      />
                    </div>
                    <div className="form-group mb-2">
                      <label style={{ color: "#222" }}>DROP</label>
                      <input
                        onChange={(e) => setDrop(e.target.value)}
                        type="text"
                        name="drop"
                        className="form-control"
                        id="fname" 
                        placeholder="Your destination place name ?"
                        required
                      />
                    </div>
                    <div className="form-group mb-2">
                      <label style={{ color: "#222" }}>FULLNAME </label>
                      <input
                        onChange={(e) => setFullname(e.target.value)}
                        type="text"
                        name="user_name" required
                        className="form-control"
                        id="fname"
                        placeholder="Your fullname"
                      />
                    </div>
                    <div className="form-group mb-2">
                      <label style={{ color: "#222" }}>CONTACT NUMBER </label>
                      <input
                        onChange={(e) => setContact(e.target.value)}
                        type="text"
                        name="contact"
                        className="form-control"
                        id="fname" required
                        placeholder="Contact Number"
                      />
                    </div>

                    <div className="form-group mb-2">
                      <label style={{ color: "#222" }}> TRAVEL DATE </label>
                      <input
                        onChange={(e) => setTravelDate(e.target.value)}
                        type="date"
                        name="password_name"
                        className="form-control"
                        id="lpass" required
                        placeholder="Password"
                      />
                    </div>
                    <div className="form-group mb-2">
                      <label style={{ color: "#222" }}> NO OF DAYS</label>
                      <select
                        type="checkbox"
                        className="custom-control-input"
                        id="exampleCheck"
                        onChange={(e) => setDays(e.target.value)}
                      >
                        <option>Select Days</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                    <div className="form-group mb-2">
                      <label style={{ color: "#222" }}> PERSON</label>
                      <select
                        type="checkbox"
                        className="custom-control-input"
                        id="exampleCheck"
                        onChange={(e) => setPerson(e.target.value)}
                      >
                        <option>Select Person</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                    {loader?(

<div className="comment-btn mb-2 pb-2 text-center border-b">
<ClipLoader
  color="#029e9d"
  // loading={loading}
  // cssOverride={override}
  size={40}
  aria-label="Loading Spinner"
  data-testid="loader"
/>
</div>
                    ):(<div className="comment-btn mb-2 pb-2 text-center border-b">
                    <input
                      type="submit"
                      className="nir-btn w-100"
                      id="submit"
                      value="Send"
                    />
                  </div>)}
                    
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
