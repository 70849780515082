import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { TravelQuery } from "../popup/TravelQuery";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Fragment } from "react";
import { Seo } from "../Seo";
export const Contact = () => {
  const location = useLocation();
  // useEffect(() => {
  //   setTimeout(() => {
  //     window.scrollTo(0, 0);
  //   }, 0);
  // }, [pathname]);

  return (
    <>
      
        <Seo
          location={location}
        />
        <section
          class="breadcrumb-main pb-20 pt-14"
          style={{ backgroundImage: "url(images/bg/bg1.jpg)" }}
        >
          <div
            class="section-shape section-shape1 top-inherit bottom-0"
            style={{ backgroundImage: "url(images/shape8.png)" }}
          ></div>
          <div class="breadcrumb-outer">
            <div class="container">
              <div class="breadcrumb-content text-center">
                <h1 class="mb-3">Contact Us</h1>
                <nav aria-label="breadcrumb" class="d-block">
                  <ul class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Contact Us
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div class="dot-overlay"></div>
        </section>

        <section class="contact-main pt-6 pb-60">
          <div class="container">
            <div class="contact-info-main mt-0">
              <div class="row">
                <div class="col-lg-10 col-offset-lg-1 mx-auto">
                  <div class="contact-info bg-white">
                    <div class="contact-info-title text-center mb-4 px-5">
                      <h3 class="mb-1">QUICK CONTACT</h3>
                    </div>
                    <div class="contact-info-content row mb-1">
                      <div class="col-lg-6 col-md-6 mb-4">
                        <div class="info-item bg-lgrey px-4 py-5 border-all text-center rounded">
                          <div class="info-icon mb-2">
                            <i class="fa fa-map-marker theme"></i>
                          </div>
                          <div class="info-content">
                            <h3>Office Location</h3>
                            <p class="m-0">
                              Champasari, Samar Nagar, Siliguri-734008
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 mb-4">
                        <div class="info-item bg-lgrey px-4 py-5 border-all text-center rounded">
                          <div class="info-icon mb-2">
                            <i class="fa fa-phone theme"></i>
                          </div>
                          <div class="info-content">
                            <h3>Phone Number</h3>
                            <p class="m-0">
                              <a class="nir-btn" href="tel:+917602628549">
                                Call@ +91-7602628549
                              </a>{" "}
                            </p>
                            <p class="m-0">
                              <a class="nir-btn" href="tel:+917908833511">
                                Call@ +91-7908833511
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 mb-4">
                        <div class="info-item bg-lgrey px-4 py-5 border-all text-center rounded">
                          <div class="info-icon mb-2">
                            <i class="fa fa-envelope theme"></i>
                          </div>
                          <div class="info-content ps-4">
                            <h3>Email Address</h3>
                            {/* <p class="m-0">
                              <a
                                href="mailto:susantabhowmick19810@gmail.com"
                                class="nir-btn"
                              >
                                susantabhowmick19810@gmail.com
                              </a>
                            </p> */}
                            <p class="m-0">
                              <a
                                href="mailto:siliguriluxurycabservice@gmail.com"
                                class="nir-btn"
                              >
                                siliguriluxurycabservice@gmail.com
                              </a>
                            </p>

                            <p>
                              <img src="images/qr1.jpeg" />
                              <img src="images/qr2.jpeg" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 mb-4">
                        <div class="info-item bg-lgrey px-4 py-5 border-all text-center rounded">
                          <div class="info-icon mb-2">
                            <i class="fa fa-credit-card theme"></i>
                          </div>
                          <div class="info-content ps-4">
                            <h3>Payment Info</h3>
                            <p class="m-0">
                              <a href="#" class="__cf_email__">
                                <b>BANK NAME:</b> BANDHAN BANK LTD
                                <br />
                                <b>ACCOUNT HOLDER NAM:</b> SILIGURI LUXURY CAB
                                SERVICE
                                <br />
                                <b>ACCOUNT NO:</b> 10220010665524
                                <br />
                                <b>IFSC CODE:</b> BDBL0001903
                                <br />
                                <b>BRANCH:</b> Pradhan Nagar
                               
                              </a>
                            </p>


      <br />
      <br />

                            <p class="m-20">
                              <a href="#" class="__cf_email__">
                                <b>BANK NAME:</b> HDFC BANK
                                <br />
                                <b>ACCOUNT HOLDER NAM:</b> SILIGURI LUXURY CAB
                                SERVICE
                                <br />
                                <b>ACCOUNT NO:</b> 50200084205699
                                <br />
                                <b>IFSC CODE:</b> HDFC0005439
                                <br />
                                <b>BRANCH:</b> CHAMPASARI DARJEELING BRANCH
                                <br />
                                <br />
                                <b>PAYTM NUMBER:</b> 7602628549
                                <br />
                                <b>GSTIN NUMBER:</b> 19APPPB8412D1ZZ
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="contact-form1" class="contact-form">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="map rounded overflow-hiddenb rounded mb-md-4">
                            <div style={{ width: "100%" }}>
                              <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28507.59345170085!2d88.4003221!3d26.7300385!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e441e14feaa199%3A0xd52e2489d3cd5efb!2sSiliguri%20Luxury%20Cab%20Service!5e0!3m2!1sen!2sin!4v1699427180651!5m2!1sen!2sin"
                                width="600"
                                height="450"
                                style={{ border: 0 }}
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                              ></iframe>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div id="contactform-error-msg"></div>
                          <TravelQuery />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      
    </>
  );
};
