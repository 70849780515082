import React from "react";
import { useLocation } from "react-router-dom";
import webContent from "../include/Config";
import { Link } from "react-router-dom";
export const HotelList = () => {
  const { state } = useLocation();
  const hotellocation = state.hotellist.hotelLocation;
  const allHotels = state.hotellist.allHotels;
  console.log("HHHH"+allHotels.length);
  return (
    <>
      <section
        class="breadcrumb-main pb-20 pt-14"
        style={{ backgroundImage: "url(images/bg/bg1.jpg)" }}
      >
        <div
          class="section-shape section-shape1 top-inherit bottom-0"
          style={{ backgroundImage: "url(images/shape8.png)" }}
        ></div>
        <div class="breadcrumb-outer">
          <div class="container">
            <div class="breadcrumb-content text-center">
              <h1 class="mb-3">{hotellocation} HOTELS</h1>
              <nav aria-label="breadcrumb" class="d-block">
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Count [{allHotels.length}]
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div class="dot-overlay"></div>
      </section>

      <section class="blog">
        <div class="container">
          <div class="row flex-row-reverse">
            <div class="col-lg-12 ps-lg-4">
              <div class="listing-inner">
                <div class="row">
                  

                  {allHotels.length > 0 &&
                    allHotels.map((hotelDetailsObj, index) => (
                      <div class="col-lg-4">
                        <div
                          class="trend-item box-shadow bg-white mb-4 rounded overflow-hidden"
                          style={{ position: "relative" }}
                        >
                          <div class="trend-image">
                            <img
                              src={
                                webContent.webLink +
                                "/assets/hotel/" +
                                hotelDetailsObj.hotelImages[0]?.images
                              }
                              alt="image"
                            />

                            <div class="trend-content-main p-4 pb-2">
                            <div class="trend-content">
                              <h4>
                               
                                {hotelDetailsObj.hotelDetails.hotelname.toUpperCase()}
                                
                              </h4>

                              <div class="entry-meta d-flex align-items-center justify-content-between">
                                <div class="entry-button d-flex align-items-center mb-2">
                                  <Link
                                    to={"/hoteldetails"}
                                    state={{ hotelfulldetails: hotelDetailsObj }}
                                    class="nir-btn"
                                  >
                                    View Hotels
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
