import React from "react";

import { Helmet } from "react-helmet";

export const Seo = ({ location }) => {
  const pageMetadata = {
    "/contactus": {
      title: "Contact Us - Siliguri Luxury Cab Service",
      description: "Contact Us - Siliguri Luxury Cab Service",
    },
    "/aboutus": {
      title: "About Us - Siliguri Luxury Cab Service",
      description: "About Us - Siliguri Luxury Cab Service",
    },
    "/ourvehicle": {
      title: "Our Vehicle - Siliguri Luxury Cab Service",
      description: "Our Vehicle Us - Siliguri Luxury Cab Service",
    },
    "/tourpackages": {
      title: "Our Tour Packages - Siliguri Luxury Cab Service",
      description: "Our Tour Packages - Siliguri Luxury Cab Service",
    },
    "/hotels": {
      title: "Hotels - Siliguri Luxury Cab Service",
      description: "Hotels - Siliguri Luxury Cab Service",
    },
  };

  const { pathname } = location;
  const { title, description, image } = pageMetadata[pathname] || {};
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="" />
    </Helmet>
  );
};
