import React, { useEffect, useState } from "react";
import webContent from "../include/Config";
import { Link } from "react-router-dom";
import axios from "axios";
export const TourPlaces = () => {
  const [nearesttour, setNearestTour] = useState([]);
  const nearesttourList = () => {
    axios
      .post(
        webContent.webLink + "/api/nearestTourPlaces.php",
        { SUBMIT: "TOURPLACES" },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //console.log(res.data);
        setNearestTour(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };
  useEffect(() => {
    nearesttourList();
  }, []);
  return (
    <>
      <section
        class="breadcrumb-main pb-20 pt-14"
        style={{ backgroundImage: "url(images/bg/bg1.jpg)" }}
      >
        <div
          class="section-shape section-shape1 top-inherit bottom-0"
          style={{ backgroundImage: "url(images/shape8.png)" }}
        ></div>
        <div class="breadcrumb-outer">
          <div class="container">
            <div class="breadcrumb-content text-center">
              <h1 class="mb-3">TOUR PLACES</h1>
              <nav aria-label="breadcrumb" class="d-block">
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    LIST OF TOUR PLACES
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div class="dot-overlay"></div>
      </section>
      <section class="blog">
        <div class="container">
          <div class="row flex-row-reverse">
            <div class="col-lg-12 ps-lg-4">
              <div class="listing-inner">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="list-results d-flex align-items-center justify-content-between">
                      <div class="list-results-sort">
                        <p class="m-0">
                          Showing [{nearesttour.length}] results
                        </p>
                      </div>
                    </div>
                  </div>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>TOUR IMAGE</th>
                        <th>LOCATION NAME</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {nearesttour.length > 0 &&
                        nearesttour.map((nearestTourObj, index) => (
                          <tr>
                            <td>
                              {" "}
                              <Link
                                to={"/tourdetails"}
                                state={{ tourInfo: nearestTourObj }}
                              >
                                <img
                                  style={{ width: "200px" }}
                                  src={
                                    webContent.webLink +
                                    "/assets/tour/" +
                                    nearestTourObj?.tourImages[0]?.images
                                  }
                                  alt={nearestTourObj?.tourImages[0]?.id}
                                />
                              </Link>
                            </td>
                            <td style={{ fontWeight: "bold" }}>
                              <Link
                                to={"/tourdetails"}
                                state={{ tourInfo: nearestTourObj }}
                              >
                                {nearestTourObj.tourDetails.title.toUpperCase()}
                              </Link>
                            </td>
                            <td>
                              <Link
                                class="nir-btn"
                                to={"/tourdetails"}
                                state={{ tourInfo: nearestTourObj }}
                              >
                                VIEW
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {/* {hotel.length > 0 &&
                    hotel.map((hotelObj, index) => (
                      <div class="col-lg-4">
                        <div class="trend-item box-shadow bg-white mb-4 rounded overflow-hidden">
                          <div class="trend-image">
                            
                          </div>
                          <div class="trend-content-main p-4 pb-2">
                            <div class="trend-content">
                              <h4>
                                <Link
                                  to={"/hotellist"}
                                  state={{ hotellist: hotelObj }}
                                >
                                  {hotelObj?.hotelLocation?.toUpperCase()}
                                </Link>
                              </h4>

                              <div class="entry-meta d-flex align-items-center justify-content-between">
                                <div class="entry-button d-flex align-items-center mb-2">
                                  <Link
                                    to={"/hotellist"}
                                    state={{ hotellist: hotelObj }}
                                    class="nir-btn"
                                  >
                                    View Hotels
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))} */}
                </div>
                {/* <div class="pagination-main text-center">
                  <ul class="pagination">
                    <li>
                      <a href="#">
                        <i
                          class="fa fa-angle-double-left"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    <li class="active">
                      <a href="#">1</a>
                    </li>
                    <li>
                      <a href="#">2</a>
                    </li>
                    <li>
                      <a href="#">3</a>
                    </li>
                    <li>
                      <a href="#">4</a>
                    </li>
                    <li>
                      <a href="#">
                        <i
                          class="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
